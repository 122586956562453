import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

// Define general type for useWindowSize hook, which includes width and height
export interface Position {
  x: number;
  y: number;
  lastX: number;
  lastY: number;
}

// Hook
export function useScroll(): Position {
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    lastX: 0,
    lastY: 0,
  });

  useEffect(() => {
    // Handler to call on window resize
    const handleScroll = debounce(() => {
      // Set window width/height to state
      setPosition((last) => ({
        x: window.scrollX,
        y: window.scrollY,
        lastX: last.x,
        lastY: last.y,
      }));
    }, 100);

    // Add event listener
    window.addEventListener('scroll', handleScroll);
    // Call handler right away so state gets updated with initial window size
    handleScroll();
    // Remove event listener on cleanup
    return () => window.removeEventListener('scroll', handleScroll);
  }, []); // Empty array ensures that effect is only run on mount

  return position;
}

/**
 * This will slide in a panel that is full height from the given direction
 * Default is right
 */
import React, { ReactElement, ReactNode, SyntheticEvent } from 'react';
import { BiX } from 'react-icons/bi';
import { twJoin } from 'tailwind-merge';

export function SlideInPanel({
  children,
  isOpen,
  toggleState,
  scrollable = true,
  from = 'right',
  displayX = true,
}: {
  children: ReactNode;
  isOpen: boolean;
  toggleState: React.Dispatch<React.SetStateAction<boolean>>;
  scrollable?: boolean;
  from?: string;
  displayX?: boolean;
}): ReactElement {
  const scrollStyle = scrollable ? 'overflow-y-scoll' : 'overflow-y-hidden';
  const paddingStyle = displayX ? 'p-2 pt-12' : '';

  const close = (e: SyntheticEvent) => {
    toggleState(false);
    e.stopPropagation();
  };

  const clickedContent = (e: SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <div
      data-open={isOpen}
      data-from={from}
      className="slideInPanel fixed top-0 z-3 h-full w-screen bg-grey-transparent data-[open=true]:visible data-[open=false]:invisible data-[open=false]:animate-[fade-out_0.3s_forwards] data-[open=true]:animate-[fade-in_0.3s_forwards]"
      onClick={close}
    >
      <div
        data-from={from}
        data-open={isOpen}
        className={twJoin(
          scrollStyle,
          paddingStyle,
          "absolute m-0 flex h-full w-[90%] max-w-[500px] flex-col gap-3 overflow-x-hidden bg-white data-[from='left']:left-0 data-[from='right']:right-0 data-[from='left']:data-[open=false]:animate-[fadeOutToLeft_0.3s_forwards] data-[from='left']:data-[open=true]:animate-[fadeInFromLeft_0.3s_forwards] data-[from='right']:data-[open=false]:animate-[fadeOutToRight_0.3s_forwards] data-[from='right']:data-[open=true]:animate-[fadeInFromRight_0.3s_forwards] dark:bg-black"
        )}
        onClick={clickedContent}
      >
        {displayX && (
          <button
            type="button"
            onClick={close}
            className="absolute right-2.5 top-2.5 border-none bg-transparent font-secondary text-4xl text-black hover:cursor-pointer hover:text-orange hover:no-underline focus:cursor-pointer focus:text-orange focus:no-underline dark:text-white"
          >
            <BiX />
          </button>
        )}
        {children}
      </div>
    </div>
  );
}

import React, { ReactElement, useContext } from 'react';
import { BiUser, BiX } from 'react-icons/bi';
import { BsSearch } from 'react-icons/bs';
import { FiChevronLeft } from 'react-icons/fi';
import { IoTrophyOutline } from 'react-icons/io5';

import { Button } from '@components/Button';
import { ButtonStyle } from '@components/Button/Button';
import { CartItemsCountButton } from '@components/Button/CartItemsCountButton';
import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { DarkMode } from '@components/DarkMode/DarkMode';
import { TooltipWrapper } from '@components/Tooltip/Tooltip';
import { useCustomer } from '@hooks/customer/useCustomer';

const ICON_SIZE = '1.75rem';

export const MenuNavBar = ({
  onNavigationBack,
}: {
  onNavigationBack: () => void;
}): ReactElement => {
  const { customer } = useCustomer();
  const {
    displayMenu: [menuIsOpen, setMenuIsOpen],
    displayCart: [quickCartIsOpen, setQuickCartIsOpen],
    menuBreadcrumbs: [menuBreadcrumbs],
  } = useContext<UIContextInterface>(UIContext);

  const handleCartEvent = () => {
    setMenuIsOpen(false);
    setQuickCartIsOpen(true);
  };

  return (
    <div className="sticky left-0 top-0 z-1 flex h-[55px] w-full justify-between bg-grey-light px-4 dark:bg-grey-darkest">
      <div className="flex w-3/5 justify-evenly">
        <button
          type="button"
          className="relative mr-2 border-none bg-transparent font-secondary text-4xl text-black hover:cursor-pointer hover:text-orange hover:no-underline focus:cursor-pointer focus:text-orange focus:no-underline dark:text-white hover:dark:text-orange"
        >
          {menuBreadcrumbs.length === 0 ? (
            <BiX onClick={() => setMenuIsOpen(false)} />
          ) : (
            <FiChevronLeft onClick={onNavigationBack} />
          )}
        </button>
        <TooltipWrapper text="Search">
          <Button
            className="w-10 self-center text-xs ring-grey-mid ring-offset-2 hover:scale-110 hover:rounded-full hover:bg-white hover:text-black hover:ring-1 hover:ring-grey-mid dark:hover:bg-grey-dark dark:hover:text-white"
            buttonStyle={ButtonStyle.tertiary}
            icon={<BsSearch size={ICON_SIZE} />}
            href="/search"
          />
        </TooltipWrapper>
        <TooltipWrapper text={customer ? 'Your Account' : 'Sign In'}>
          <Button
            className="w-10 self-center rounded-full ring-grey-mid ring-offset-2 hover:scale-110 hover:bg-white hover:text-black hover:ring-1 hover:ring-grey-mid dark:hover:bg-grey-dark dark:hover:text-white"
            buttonStyle={ButtonStyle.tertiary}
            icon={<BiUser size={ICON_SIZE} />}
            href={customer ? '/account' : '/login'}
          />
        </TooltipWrapper>
        <TooltipWrapper text="Rewards">
          <Button
            className="w-10 self-center rounded-full ring-grey-mid ring-offset-2 hover:scale-110 hover:bg-white hover:text-black hover:ring-1 hover:ring-grey-mid dark:hover:bg-grey-dark dark:hover:text-white xl:flex"
            buttonStyle={ButtonStyle.tertiary}
            icon={<IoTrophyOutline size={ICON_SIZE} />}
            href="/rewards"
          />
        </TooltipWrapper>
        <DarkMode className="relative self-center rounded-full p-0 ring-grey-mid ring-offset-2 hover:scale-110 hover:bg-white hover:text-black hover:ring-1 hover:ring-grey-mid dark:hover:bg-grey-dark dark:hover:text-white xl:flex" />
      </div>
      <CartItemsCountButton handleCartEvent={handleCartEvent} />
    </div>
  );
};

import { useRouter } from 'next/router';
import React, { ReactElement, useEffect, useState } from 'react';
import { BiCart } from 'react-icons/bi';

import { Button } from '@components/Button';
import { ButtonStyle } from '@components/Button/Button';
import { TooltipWrapper } from '@components/Tooltip/Tooltip';
import { useCart } from '@hooks/cart/useCart';

const ICON_SIZE = '1.75rem';

export const CartItemsCountButton = ({ handleCartEvent }): ReactElement => {
  const [itemsCount, setItemsCount] = useState(0);
  const router = useRouter();
  const { cart } = useCart();

  const disabled =
    router.asPath.includes('/cart') || router.asPath.includes('/info');

  useEffect(() => {
    let mounted = true;

    const countItem = (count: number, item: any) => count + item.quantity;
    const countItems = (count: number, items: any[]) =>
      items.reduce(countItem, count);
    const newItemsCount = Object.values(cart?.line_items ?? {}).reduce(
      countItems,
      0
    );

    if (mounted) setItemsCount(newItemsCount);

    return function cleanup() {
      mounted = false;
    };
  }, [cart]);

  return (
    <TooltipWrapper text="Cart">
      <Button
        aria-label="Cart"
        onClick={handleCartEvent}
        disabled={disabled}
        className="relative h-10 w-10 self-center overflow-visible px-1 text-xs ring-grey-mid ring-offset-2 hover:scale-110 hover:rounded-full hover:bg-white hover:text-black hover:ring-1 hover:ring-grey-mid disabled:text-grey-mid dark:hover:bg-grey-dark dark:hover:text-white dark:disabled:bg-transparent xl:flex"
        buttonStyle={ButtonStyle.tertiary}
        icon={<BiCart size={ICON_SIZE} />}
      >
        {itemsCount > 0 && !disabled ? (
          <span className="absolute right-0 top-0 flex h-4 w-4 items-center justify-center rounded-full bg-red font-primary text-xs text-white">
            <span>{itemsCount}</span>
          </span>
        ) : (
          <></>
        )}
      </Button>
    </TooltipWrapper>
  );
};

import { ReactElement } from 'react';
import { twJoin } from 'tailwind-merge';

import { mergeStyles } from '@lib/styles';

export function HorizontalDivider({
  className,
}: {
  className?: string;
}): ReactElement {
  return (
    <div
      className={twJoin(
        'hr',
        mergeStyles(
          className ?? '',
          `mx-0 my-10 h-[1px] min-h-[1px] w-full bg-gradient-to-r 
        from-white from-0% via-orange via-50% 
        to-white to-100% dark:from-black dark:via-orange dark:to-black`
        )
      )}
    />
  );
}

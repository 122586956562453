import React, { useContext, useEffect, useRef } from 'react';

import {
  UIContext,
  UIContextInterface,
} from '@components/Context/UIContext/UIContext';
import { CustomImage } from '@components/Image/Image';
import { RewardsLink } from '@components/Typography/TextLink/TextLink';
import { TextLink } from '@components/Typography/TextLink/TextLink';
import { useCustomer } from '@hooks/customer/useCustomer';
import { MenuBannerItem, SubheaderDetails } from '@interfaces/Menu';

export const MenuHeader = ({
  bannerInfo,
  handleBannerRedirect,
  details,
}: {
  bannerInfo: MenuBannerItem | undefined;
  handleBannerRedirect: () => void;
  details: SubheaderDetails | undefined;
  animationDir: string;
}) => {
  const { customer } = useCustomer();
  const scrollRef = useRef<null | HTMLDivElement>(null);
  const {
    menuBreadcrumbs: [menuBreadcrumbs],
  } = useContext<UIContextInterface>(UIContext);

  useEffect(() => {
    // when navigation in menu changes, scroll to top of menu
    // ref is on the nav bar bc it's the top of the menu
    if (scrollRef?.current) scrollRef?.current.scrollIntoView();
  }, [menuBreadcrumbs]);

  return menuBreadcrumbs.length === 0 ? (
    <div
      className="w-auto scroll-mt-[40px] pb-[30px] pt-[60px]"
      ref={scrollRef}
    >
      {customer ? (
        <div>
          <h2 className="pb-3 italic">
            Welcome back,&nbsp;
            {customer.firstName}
          </h2>
          <p className="mb-4">
            Check your earned <RewardsLink>reward points</RewardsLink>.{' '}
          </p>
        </div>
      ) : (
        <div>
          <h2 className="pb-3 italic">Hey there, Bulk fan!</h2>
          <p className="pb-3">
            <TextLink href="/login">Sign in</TextLink> or{' '}
            <TextLink href="/create-account">create an account</TextLink> to
            earn points with every purchase.
          </p>
        </div>
      )}
      {bannerInfo?.image && (
        <button
          type="button"
          onClick={handleBannerRedirect}
          className={`mx-0 my-2.5 block w-full translate-x-0 border-none bg-transparent p-0`}
        >
          <CustomImage
            image={bannerInfo.image}
            width={691}
            alt={bannerInfo.title}
          />
        </button>
      )}
    </div>
  ) : (
    <div
      className="w-auto scroll-mt-[40px] pb-[20px] pt-[60px]"
      ref={scrollRef}
    >
      <h2 className="italic">{menuBreadcrumbs[menuBreadcrumbs.length - 1]}</h2>
      {details && details.description && (
        <p className="my-2 text-xs">{details.description}</p>
      )}
      {details && details.link && (
        <TextLink className="text-xs" href={details.link.slug}>
          {details.link.title}
        </TextLink>
      )}
    </div>
  );
};
